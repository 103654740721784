import { ChainList } from "./ChainList";
import { useMUD } from "./MUDContext";
import { TransactionList } from "./TransactionList";
import { AccountButton, useAppAccountClient } from "@latticexyz/account-kit";
import { createSystemExecutor } from "./createSystemExecutor";
import { useWorldContract } from "./useWorldContract";
import { useMemo } from "react";

const styleUnset = { all: "unset" } as const;

export const App = () => {
  const { network } = useMUD();
  const { tables, useStore } = network;
  const appAccountClient = useAppAccountClient();
  const worldContract = useWorldContract(appAccountClient);
  const executeSystem = useMemo(() => {
    if (!worldContract) return;
    return createSystemExecutor({ worldContract, network }).executeSystem;
  }, [worldContract, network]);

  const tasks = useStore((state) => {
    const records = Object.values(state.getRecords(tables.Tasks));
    records.sort((a, b) => Number(a.value.createdAt - b.value.createdAt));
    return records;
  });

  if (!executeSystem) return <AccountButton />;

  return (
    <div style={{ display: "flex", flexDirection: "row", height: "800px" }}>
      <table>
        <tbody>
          {tasks.map((task) => (
            <tr key={task.id}>
              <td align="right">
                <input
                  type="checkbox"
                  checked={task.value.completedAt > 0n}
                  title={
                    task.value.completedAt === 0n
                      ? "Mark task as completed"
                      : "Mark task as incomplete"
                  }
                  onChange={async (event) => {
                    event.preventDefault();

                    try {
                      if (task.value.completedAt === 0n) {
                        await executeSystem({
                          systemCall: "completeTask",
                          args: [[task.key.id]],
                        });
                      } else {
                        await executeSystem({
                          systemCall: "resetTask",
                          args: [[task.key.id]],
                        });
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                />
              </td>
              <td>
                {task.value.completedAt > 0n ? (
                  <s>{task.value.description}</s>
                ) : (
                  <>{task.value.description}</>
                )}
              </td>
              <td align="right">
                <button
                  type="button"
                  title="Delete task"
                  style={styleUnset}
                  onClick={async (event) => {
                    event.preventDefault();
                    if (
                      !window.confirm(
                        "Are you sure you want to delete this task?"
                      )
                    )
                      return;

                    const button = event.currentTarget;
                    button.disabled = true;
                    try {
                      await executeSystem({
                        systemCall: "deleteTask",
                        args: [[task.key.id]],
                      });
                    } finally {
                      button.disabled = false;
                    }
                  }}
                >
                  &times;
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <input type="checkbox" disabled />
            </td>
            <td colSpan={2}>
              <form
                onSubmit={async (event) => {
                  event.preventDefault();
                  const form = event.currentTarget;
                  const fieldset = form.querySelector("fieldset");
                  if (!(fieldset instanceof HTMLFieldSetElement)) return;

                  const formData = new FormData(form);
                  const desc = formData.get("description");
                  if (typeof desc !== "string") return;

                  fieldset.disabled = true;
                  try {
                    await executeSystem({
                      systemCall: "addTask",
                      args: [[desc]],
                    });
                    form.reset();
                  } finally {
                    fieldset.disabled = false;
                  }
                }}
              >
                <fieldset style={styleUnset}>
                  <input type="text" name="description" />{" "}
                  <button type="submit" title="Add task">
                    Add
                  </button>
                </fieldset>
              </form>
            </td>
          </tr>
        </tfoot>
      </table>

      <div style={{ width: "1em" }} />

      <TransactionList />
      <div>
        <AccountButton />
        <ChainList />
      </div>
    </div>
  );
};
