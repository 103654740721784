import { useAppAccountClient } from "@latticexyz/account-kit";
import { useMemo } from "react";
import IWorldAbi from "contracts/out/IWorld.sol/IWorld.abi.json";
import {
  ClientConfig,
  Hex,
  createPublicClient,
  fallback,
  getContract,
  http,
  webSocket,
} from "viem";
import { getNetworkConfig } from "./mud/getNetworkConfig";
import { transportObserver } from "@latticexyz/common";

export function useWorldContract(
  appAccountClient: ReturnType<typeof useAppAccountClient>
) {
  const network = useMemo(() => getNetworkConfig(), []);

  const clientOptions = {
    chain: network.chain,
    transport: transportObserver(fallback([webSocket(), http()])),
    pollingInterval: 500,
  } as const satisfies ClientConfig;
  const publicClient = createPublicClient(clientOptions);

  return useMemo(() => {
    if (!appAccountClient) return;
    return getContract({
      address: network.worldAddress as Hex,
      abi: IWorldAbi,
      client: { public: publicClient, wallet: appAccountClient },
    });
  }, [appAccountClient, network.worldAddress, publicClient]);
}
